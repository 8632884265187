/* TODO slider animation */
.hyam-animate-slide {
  animation: hyam-slide 4s linear infinite;
}
@keyframes hyam-slide {
  from {
    transform: translate3d(-100vw, 0, 0);
  }
  to {
    transform: translate3d(0vw, 0, 0);
  }
}
@layer utilities {
  .hyam-animate-text {
    @apply [text-shadow:_0.4px_0_transparent]  hover:[text-shadow:_0.4px_0_black] transition duration-200;
  }
  .hyam-animate-text-white {
    @apply [text-shadow:_0.4px_0_transparent]  hover:[text-shadow:_0.4px_0_white] transition duration-200;
  }
}

/* Examples:
  .card {
    background-color: theme("colors.white");
    border-radius: theme("borderRadius.lg");
    padding: theme("spacing.6");
    box-shadow: theme("boxShadow.xl");
  }
  .select2-search {
    @apply border border-gray-300 rounded;
  }
  */
