.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha_disclaimer {
  & a {
    text-decoration: underline;
  }
}

html {
  scroll-behavior: smooth;
}
