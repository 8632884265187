@import "tailwindcss/base";

@import "tailwindcss/components";

@import "../packages/hyam-core/styles/variables.css";
@import "../packages/hyam-core/styles/typography.css";
@import "../packages/hyam-core/styles/layout.css";
@import "../packages/hyam-core/styles/grid.css";
@import "../packages/hyam-core/styles/hyam-w-cols.css";
@import "../packages/hyam-core/styles/hyam-col-row-gap.css";
@import "../packages/hyam-core/styles/animation.css";
@import "../packages/hyam-core/styles/global.css";

@import "../styles/fonts.css";
@import "tailwindcss/utilities";
