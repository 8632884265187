@layer components {
  /** Defines the content width for the app's layout */
  .hyam-layout-w {
    width: var(--hyam-layout-w-sm);
  }

  /** Defines the horizontal paddings for the app's layout, note: we only need px > 0 on sm and smaller screen sizes */
  .hyam-layout-px {
    padding-left: var(--hyam-layout-px-sm);
    padding-right: var(--hyam-layout-px-sm);
  }

  /** Defines the content width and horizontal paddings for the app's layout */
  .hyam-layout {
    @apply hyam-layout-w hyam-layout-px;
  }

  /** Vertically centers hyam-layouts, hould be used as its container */
  .hyam-layout-container {
    @apply flex flex-col items-center;
  }

  @screen md {
    .hyam-layout-w {
      width: var(--hyam-layout-w-md);
    }
    .hyam-layout-px {
      padding-left: var(--hyam-layout-px-md);
      padding-right: var(--hyam-layout-px-md);
    }
  }

  @screen lg {
    .hyam-layout-w {
      width: var(--hyam-layout-w-lg);
    }
  }

  @screen xl {
    .hyam-layout-w {
      width: var(--hyam-layout-w-xl);
    }
  }

  @screen 2xl {
    .hyam-layout-w {
      width: var(--hyam-layout-w-2xl);
    }
  }

  /**
   * Full width of screen minus the scrollbar. This class only works when used outside the layout.
   * For breaking out of the layout, refer to hyam-layout-full-width
   */
  .hyam-full-width {
    width: var(--hyam-100vw);
  }

  /**
   * Make an element 100vw even if it is inside the app's layout
   * Taken from here: https://coderwall.com/p/hkgamw/creating-full-width-100-container-inside-fixed-width-container
   */
  .hyam-layout-full-width {
    width: var(--hyam-100vw);
    position: relative;
    margin-left: calc(-0.5 * var(--hyam-100vw));
    left: 50%;
  }

  /** Full height minus nav bar, etc. */
  .hyam-layout-full-height {
    height: calc(100vh - var(--hyam-nav-bar-height));
  }

  /** Container for all slices */
  .hyam-slice-container {
    @apply py-6 md:py-16 lg:py-20;
  }

  /** Container for all slices (smaller) */
  .hyam-slice-container-small {
    @apply my-12 md:my-16 lg:my-16;
  }

  /** Container for all slices that need to use margin and not padding */
  /** for example slices with a background color or images*/
  .hyam-slice-container-m {
    @apply mb-12 md:mb-20 lg:mb-24;
  }
}
