:root {
  /**
   * Horizontal paddings for the layout
   * NOTE: only <= sm needs a fixed horizontal padding. Form > sm, we set the padding to 0 and center the content.
   */
  --hyam-layout-px-sm: theme("spacing.4");
  --hyam-layout-px-md: 0px;

  /** Widths of layout's containers for all responsive sizes */
  --hyam-layout-w-sm: 100%;
  --hyam-layout-w-md: 720px;
  --hyam-layout-w-lg: 950px;
  --hyam-layout-w-xl: 1260px;
  --hyam-layout-w-2xl: 1400px;

  /** Widths and heights of grid system (hyam-cols, hyam-grid, etc.) for all reasponsive sizes */
  --hyam-grid-gap-sm: theme("spacing.4");
  --hyam-grid-gap-md: theme("spacing.8");
  --hyam-grid-gap-lg: theme("spacing.10");
  --hyam-grid-gap-xl: theme("spacing.10");
  --hyam-grid-gap-2xl: theme("spacing.10");

  /** Height of navigation bar */
  --hyam-nav-bar-height: 62px;

  /**
   * Keep track of the width of the scrollbar
   * NOTE: This gets updated via useLayoutCssVariables()
   */
  --hyam-scrollbar-width: 0px;

  /** Viewport width minus scrollbar width */
  --hyam-100vw: calc(100vw - var(--hyam-scrollbar-width));

  /** The width of the grid columns for all responsive screen sizes */
  --hyam-w-col-md: calc((var(--hyam-layout-w-md) - var(--hyam-grid-gap-md) * 5) / 6);
  --hyam-w-col-lg: calc((var(--hyam-layout-w-lg) - var(--hyam-grid-gap-lg) * 5) / 6);
  --hyam-w-col-xl: calc((var(--hyam-layout-w-xl) - var(--hyam-grid-gap-lg) * 11) / 12);
  --hyam-w-col-2xl: calc((var(--hyam-layout-w-2xl) - var(--hyam-grid-gap-lg) * 11) / 12);
  /* sm is an exception, since there we use 100% width for the container */
  --hyam-w-col-sm: calc(
    0.5 * (var(--hyam-100vw) - var(--hyam-grid-gap-sm) - var(--hyam-layout-px-sm) * 2)
  );
}
