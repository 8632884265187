@layer components {
  .hyam-col-gap {
    grid-column-gap: var(--hyam-grid-gap-sm);
  }
  .hyam-row-gap {
    grid-row-gap: var(--hyam-grid-gap-sm);
  }

  @screen md {
    .hyam-col-gap {
      grid-column-gap: var(--hyam-grid-gap-md);
    }
    .hyam-row-gap {
      grid-row-gap: var(--hyam-grid-gap-md);
    }
  }

  @screen lg {
    .hyam-col-gap {
      grid-column-gap: var(--hyam-grid-gap-lg);
    }
    .hyam-row-gap {
      grid-row-gap: var(--hyam-grid-gap-lg);
    }
  }

  @screen xl {
    .hyam-col-gap {
      grid-column-gap: var(--hyam-grid-gap-xl);
    }
    .hyam-row-gap {
      grid-row-gap: var(--hyam-grid-gap-xl);
    }
  }

  @screen 2xl {
    .hyam-col-gap {
      grid-column-gap: var(--hyam-grid-gap-2xl);
    }
    .hyam-row-gap {
      grid-row-gap: var(--hyam-grid-gap-2xl);
    }
  }
}
