/* Typography */
@layer components {
  .hyam-text-5xl {
    @apply font-display text-m-5xl  lg:text-5xl text-hyam-black antialiased;
  }
  .hyam-text-4xl {
    @apply font-display text-m-4xl  lg:text-4xl mb-4 lg:mb-6 text-hyam-black antialiased;
  }
  .hyam-text-3xl {
    @apply font-display text-m-3xl  lg:text-3xl mb-4 lg:mb-6 text-hyam-black antialiased;
  }
  .hyam-text-2xl {
    @apply font-display text-m-2xl   lg:text-2xl mb-4 lg:mb-6 text-hyam-black antialiased;
  }

  /* H5 = Body-Large */
  .hyam-text-xl {
    @apply font-display text-m-xl  font-normal lg:text-xl  mb-4 lg:mb-6 text-hyam-black antialiased;
  }

  .hyam-text-medium {
    @apply text-m-medium font-body font-normal lg:text-medium mb-6 text-hyam-black antialiased;
  }

  .hyam-text-small {
    @apply text-m-small font-body font-normal lg:text-small mb-6 text-hyam-black antialiased;
  }

  .hyam-text-caption {
    @apply font-body font-normal text-caption mb-6 text-hyam-black antialiased;
  }
}
