@layer components {
  .hyam-cols {
    @apply grid xl:grid-cols-12 md:grid-cols-6 grid-cols-2;
    grid-column-gap: var(--hyam-grid-gap-sm);
  }
  .hyam-flex-cols {
    @apply flex xl:grid-cols-12 md:grid-cols-6 grid-cols-2;
    grid-column-gap: var(--hyam-grid-gap-sm);
  }
  .hyam-grid {
    @apply hyam-cols;
    grid-row-gap: var(--hyam-grid-gap-sm);
  }

  @screen md {
    .hyam-cols {
      grid-column-gap: var(--hyam-grid-gap-md);
    }
    .hyam-flex-cols {
      grid-column-gap: var(--hyam-grid-gap-md);
    }
    .hyam-grid {
      grid-row-gap: var(--hyam-grid-gap-md);
    }
  }

  @screen lg {
    .hyam-cols {
      grid-column-gap: var(--hyam-grid-gap-lg);
    }
    .hyam-flex-cols {
      grid-column-gap: var(--hyam-grid-gap-lg);
    }
    .hyam-grid {
      grid-row-gap: var(--hyam-grid-gap-lg);
    }
  }
}
