@font-face {
  font-family: "TWKLausanne";
  src: url("../src/fonts/TWKLausanne-400.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PPFormulaCondensed";
  src: url("../src/fonts/PPFormulaCondensed-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
