/**
 * hyam-w-cols-* utility classes for setting the width of elements based on
 * columns + columns gaps
 */
@layer components {
  .hyam-w-cols-1 {
    width: calc(var(--hyam-w-col-sm));
  }
  .hyam-w-cols-2 {
    width: calc(var(--hyam-w-col-sm) * 2 + var(--hyam-grid-gap-sm));
  }

  @screen md {
    .hyam-w-cols-1 {
      width: calc(var(--hyam-w-col-md));
    }
    .hyam-w-cols-2 {
      width: calc(var(--hyam-w-col-md) * 2 + var(--hyam-grid-gap-md));
    }
    .hyam-w-cols-3 {
      width: calc(var(--hyam-w-col-md) * 3 + var(--hyam-grid-gap-md) * 2);
    }
    .hyam-w-cols-4 {
      width: calc(var(--hyam-w-col-md) * 4 + var(--hyam-grid-gap-md) * 3);
    }
    .hyam-w-cols-5 {
      width: calc(var(--hyam-w-col-md) * 5 + var(--hyam-grid-gap-md) * 4);
    }
    .hyam-w-cols-6 {
      width: calc(var(--hyam-w-col-md) * 6 + var(--hyam-grid-gap-md) * 5);
    }
  }

  @screen lg {
    .hyam-w-cols-1 {
      width: calc(var(--hyam-w-col-lg));
    }
    .hyam-w-cols-2 {
      width: calc(var(--hyam-w-col-lg) * 2 + var(--hyam-grid-gap-lg));
    }
    .hyam-w-cols-3 {
      width: calc(var(--hyam-w-col-lg) * 3 + var(--hyam-grid-gap-lg) * 2);
    }
    .hyam-w-cols-4 {
      width: calc(var(--hyam-w-col-lg) * 4 + var(--hyam-grid-gap-lg) * 3);
    }
    .hyam-w-cols-5 {
      width: calc(var(--hyam-w-col-lg) * 5 + var(--hyam-grid-gap-lg) * 4);
    }
    .hyam-w-cols-6 {
      width: calc(var(--hyam-w-col-lg) * 6 + var(--hyam-grid-gap-lg) * 5);
    }
  }

  @screen xl {
    .hyam-w-cols-1 {
      width: calc(var(--hyam-w-col-xl));
    }
    .hyam-w-cols-2 {
      width: calc(var(--hyam-w-col-xl) * 2 + var(--hyam-grid-gap-xl));
    }
    .hyam-w-cols-3 {
      width: calc(var(--hyam-w-col-xl) * 3 + var(--hyam-grid-gap-xl) * 2);
    }
    .hyam-w-cols-4 {
      width: calc(var(--hyam-w-col-xl) * 4 + var(--hyam-grid-gap-xl) * 3);
    }
    .hyam-w-cols-5 {
      width: calc(var(--hyam-w-col-xl) * 5 + var(--hyam-grid-gap-xl) * 4);
    }
    .hyam-w-cols-6 {
      width: calc(var(--hyam-w-col-xl) * 6 + var(--hyam-grid-gap-xl) * 5);
    }
    .hyam-w-cols-7 {
      width: calc(var(--hyam-w-col-xl) * 7 + var(--hyam-grid-gap-xl) * 6);
    }
    .hyam-w-cols-8 {
      width: calc(var(--hyam-w-col-xl) * 8 + var(--hyam-grid-gap-xl) * 7);
    }
    .hyam-w-cols-9 {
      width: calc(var(--hyam-w-col-xl) * 9 + var(--hyam-grid-gap-xl) * 8);
    }
    .hyam-w-cols-10 {
      width: calc(var(--hyam-w-col-xl) * 10 + var(--hyam-grid-gap-xl) * 9);
    }
    .hyam-w-cols-11 {
      width: calc(var(--hyam-w-col-xl) * 11 + var(--hyam-grid-gap-xl) * 10);
    }
    .hyam-w-cols-12 {
      width: calc(var(--hyam-w-col-xl) * 12 + var(--hyam-grid-gap-xl) * 11);
    }
  }

  @screen 2xl {
    .hyam-w-cols-1 {
      width: calc(var(--hyam-w-col-2xl));
    }
    .hyam-w-cols-2 {
      width: calc(var(--hyam-w-col-2xl) * 2 + var(--hyam-grid-gap-2xl));
    }
    .hyam-w-cols-3 {
      width: calc(var(--hyam-w-col-2xl) * 3 + var(--hyam-grid-gap-2xl) * 2);
    }
    .hyam-w-cols-4 {
      width: calc(var(--hyam-w-col-2xl) * 4 + var(--hyam-grid-gap-2xl) * 3);
    }
    .hyam-w-cols-5 {
      width: calc(var(--hyam-w-col-2xl) * 5 + var(--hyam-grid-gap-2xl) * 4);
    }
    .hyam-w-cols-6 {
      width: calc(var(--hyam-w-col-2xl) * 6 + var(--hyam-grid-gap-2xl) * 5);
    }
    .hyam-w-cols-7 {
      width: calc(var(--hyam-w-col-2xl) * 7 + var(--hyam-grid-gap-2xl) * 6);
    }
    .hyam-w-cols-8 {
      width: calc(var(--hyam-w-col-2xl) * 8 + var(--hyam-grid-gap-2xl) * 7);
    }
    .hyam-w-cols-9 {
      width: calc(var(--hyam-w-col-2xl) * 9 + var(--hyam-grid-gap-2xl) * 8);
    }
    .hyam-w-cols-10 {
      width: calc(var(--hyam-w-col-2xl) * 10 + var(--hyam-grid-gap-2xl) * 9);
    }
    .hyam-w-cols-11 {
      width: calc(var(--hyam-w-col-2xl) * 11 + var(--hyam-grid-gap-2xl) * 10);
    }
    .hyam-w-cols-12 {
      width: calc(var(--hyam-w-col-2xl) * 12 + var(--hyam-grid-gap-2xl) * 11);
    }
  }
}
